import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/proxy',
    name: 'proxy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Proxy.vue')
  },
  {
    path: '/fnq',
    name: 'fnq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FnQ.vue')
  },
  {
    path: '/joinus',
    name: 'joinus',
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinUs.vue')
  },
  {
    path: '/tkline',
    name: 'tkline',
    component: () => import(/* webpackChunkName: "about" */ '../views/TkLine.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/legalstatement',
    name: 'LegalStatement',
    component: () => import(/* webpackChunkName: "about" */ '../views/LegalStatement.vue')
  },

  {
    path: '/reg',
    name: 'reg',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reg.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/clientarea/change_password',
    name: 'changepwd',
    component: () => import(/* webpackChunkName: "about" */ '../views/ClientArea/ChangePwd.vue')
  },
  {
    path: '/clientarea/reset_password',
    name: 'restpwd',
    component: () => import(/* webpackChunkName: "about" */ '../views/ClientArea/RestPwd.vue')
  },
  {
    path:'/m_clientarea',
    name:'m_clientarea',
    component: () => import(/* webpackChunkName: "about" */ '../components/ClientArea/MobileIndex'),
    children: [
      {
        path: '/m/dashboard',
        name: 'm_dashboard',
        component:  () => import('../components/Dash_view/dash'),
      },
      {
        path: '/m/ip/buy_ip',
        name: 'm_ip_buy_ip',
        component:  () => import('../components/Dash_view/ip/buy_ip'),
      },
      {
        path: '/m/ip/my_ip',
        name: 'm_ip_my_ip',
        component:  () => import('../components/Dash_view/ip/my_ip'),
      },
      {
        path: '/m/live/buy_ip/:live_type?',
        name: 'm_live_buy_ip',
        component:  () => import('../components/Dash_view/live/buy_live_ip'),
      },
      {
        path: '/m/live/my_ip',
        name: 'm_live_my_ip',
        component:  () => import('../components/Dash_view/live/my_live_ip'),
      },
      {
        path: '/m/co_page',
        name: 'm_co_page',
        component:  () => import('../components/Dash_view/order_management/co_page'),
      },
      {
        path: '/m/coupon_management',
        name: 'm_coupon_management',
        component:  () => import('../components/Dash_view/order_management/coupon_management'),
      },
      {
        path: '/m/ip_management',
        name: 'm_ip_management',
        component:  () => import('../components/Dash_view/order_management/ip_management'),
      },
      {
        path: '/m/live_ip_management',
        name: 'm_live_ip_management',
        component:  () => import('../components/Dash_view/order_management/live_ip_management'),
      },
      {
        path: '/m/help',
        name: 'm_help',
        component:  () => import('../components/Dash_view/help'),
      },
    ],
  },
  {
    path: '/clientarea',
    name: 'clientarea',
    component: () => import(/* webpackChunkName: "about" */ '../views/ClientArea/'),
    children: [
      {
        path: '/clientarea/dashboard',
        name: 'dashboard',
        component:  () => import('../components/Dash_view/dash'),
      },
      {
        path: '/clientarea/ip/buy_ip',
        name: 'ip_buy_ip',
        component:  () => import('../components/Dash_view/ip/buy_ip'),
      },
      {
        path: '/clientarea/ip/my_ip',
        name: 'ip_my_ip',
        component:  () => import('../components/Dash_view/ip/my_ip'),
      },
      {
        path: '/clientarea/live/buy_ip/:live_type?',
        name: 'live_buy_ip',
        component:  () => import('../components/Dash_view/live/buy_live_ip'),
      },
      {
        path: '/clientarea/live/my_ip',
        name: 'live_my_ip',
        component:  () => import('../components/Dash_view/live/my_live_ip'),
      },
      {
        path: '/clientarea/co_page',
        name: 'co_page',
        component:  () => import('../components/Dash_view/order_management/co_page'),
      },
      {
        path: '/clientarea/coupon_management',
        name: 'coupon_management',
        component:  () => import('../components/Dash_view/order_management/coupon_management'),
      },
      {
        path: '/clientarea/ip_management',
        name: 'ip_management',
        component:  () => import('../components/Dash_view/order_management/ip_management'),
      },
      {
        path: '/clientarea/live_ip_management',
        name: 'live_ip_management',
        component:  () => import('../components/Dash_view/order_management/live_ip_management'),
      },
      {
        path: '/clientarea/help',
        name: 'help',
        component:  () => import('../components/Dash_view/help'),
      },
    ],
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,

})
router.afterEach((to,from,next)=>{
  window,scrollTo(0,0);
      const tabContent = document.querySelector('.tab-content');
    // console.log(tabContent.scrollTop);
    
    if (tabContent) {
      try{
        tabContent.scrollTop = 0; // 将滚动条位置设置为零
      }
      catch{

      }
    }
  // document.documentElement.scrollTop = 0;
})

export default router
